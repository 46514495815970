.header-wrapper{

    .logo-header{
        position: relative;
        z-index: 10;
        max-width: 272px;
        width: 100%;
    }

    .fullscreen-bg__video{
        position: absolute; 
        top: 0; 
        left: 50%; 
        min-width: 100%;
        min-height: 100vh;
        transform: translateX(-50%);
        overflow: hidden;
    }
   
    .header-main{
        height: 90vh; 
        position: relative;
        overflow: hidden;

        &:before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url('../src/images/shader.png');
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 1;
            z-index: 9;
            pointer-events: none;
        }
        
        &__copy{
            font-size: 52px;
            color: #fff;
            font-weight: 600;
            overflow: hidden;
            position: relative;
            z-index: 10;

            @media(min-width: 1400px){
                padding-top: 150px !important;
            }

            @media(max-width: 768px){
                font-size: 45px;
            }

            @media(max-width:600px){
                margin-top: 80px;
            }
            
        }

        &__subcopy{
            font-size: 52px;
            color: #fff;
            font-weight: 200;
            position: relative;
            z-index: 10;

            @media(max-width: 768px){
                font-size: 45px;
            }
        }
    }
    
    .header-bottombar{
        height: 10vh; 
        background: linear-gradient(to right, #1b70b7 1%, #1b70b7 30%, #1b70b7 30%, #502c72 100%);
        position: relative;
        z-index: 10;

        &:after{
            content: '';
            position: absolute;
            top: -200px;
            left: 0px;
            width: 0;
            height: 0;
            border-bottom: 200px solid #1b70b7;
            border-right: 250px solid transparent;
            z-index: 10;

            @media(max-width: 768px){
                display: none;
            }
        }

        .scrolldown{
            text-transform: uppercase;
            color: #fff;
            font-size: 12px;
            font-weight: 300;
            position: absolute;
            top: 50px;
            left: 40px;
            transform-origin: 0 0;
            transform: rotate(-90deg) translateY(0);
            z-index: 99;
            margin: 0;
            cursor: pointer;

            @media(max-width: 768px){
                font-size: 0;
                left: 50%;
                transform-origin: 0% 50%;
                top: -80px;
                transform: rotate(-90deg) translateX(-50%);
            }

            .animate-arrow{
                max-width: 48px;
                width: 100%;
                animation: moved 1.8s infinite;
            }
        }
    }

    @keyframes moved{
        0%{
            transform: translateX(0);
        }
        50%{
            transform:translateX(-10px);
        }
        100%{
            transform: translateX(0);
        }
    }
    
    .header-content{
        padding-bottom: 100px;
        position: relative;
        overflow: hidden;
        background: linear-gradient(to right, #1b70b7 1%, #1b70b7 30%, #1b70b7 30%, #502c72 100%);

        &:after{
            content: '';
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 0;
            height: 0;
            border-bottom: 250px solid #fff;
            border-left: 200px solid transparent;
            z-index: 10;

            @media(max-width: 768px){
                border-bottom: 150px solid #fff;
                border-left: 100px solid transparent;
            }
        }

        &_paragraph{
            color: #fff;
            font-weight: 300;
            font-size: 15px;
            line-height: 30px;
        }

        &_paragraph-small{
            @extend .header-content_paragraph;
            font-size: 13px;
        }

        &_imgbox{
            min-height: 70px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
        }
    }
}



.card-section{
    height: 100%;
    position: fixed; 
    top: 0; 
    right: 0;

    @media(max-width: 992px){
        display: none;
    }

    .card_box{
        height: 100vh;

        .overheighted{
            height: 34vh;
        }

        &__single{ 
            height: 33vh;
            background: #fff;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid #e3e3e3;
            transition: .5s transform;

            &:hover{
                .card_box__single___right{
                    transition: .5s transform;
                    transform: translateX(-20px);
                }
               
            }

            &___left{
                width: 49%;

                @media(max-width: 1200px){
                    width: 100%;
                }

                @media(max-width: 992px){
                    padding: 10px 15px;
                }

                &____description{
                    color: #696969;
                    font-size: 13px;
                    font-weight: 300;
                    text-transform: uppercase;
                    text-align: right;
                }
            }

            &___right{
                width: 49%;
                transition: .5s transform;

                @media(max-width: 1200px){
                    display: none;
                }

            }
        }
    }
}

.contact{
    margin-top: 25px;

    &_singlebox{
        color: #696969;

        p{
            font-size: 13px;
        }
       
    }
}
