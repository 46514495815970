.white-shadow-btn{
    outline: none;
    border: none;
    background: #fff;
    padding: 8px 50px;
    border-radius: 25px;
    font-weight: 300;
    color: #848484 !important;
    font-size: 22px;
    box-shadow: -1px 9px 49px -12px rgba(0,0,0,0.75);
    cursor: pointer;
    display: inline-block;

    @media(max-width: 768px){
        font-size: 16px;
    }

    &:focus{
        outline: none;
    }
    
    &:hover{
        text-decoration: none;
        color: #848484;
    }
        
}