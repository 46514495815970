body{
    font-family: 'Titillium Web', sans-serif;
}

.blog-btn{
    z-index: 9999999999;
    position: absolute;
    top: -23px;
    right: 40px;
}

.section-title{
    position: relative;
    text-align: center;
    margin: 50px 0 50px 0;
    color: #fff;
    font-size: 40px;
    z-index: 1;
    font-weight: 300;
    display: inline-block;
    
    @media(max-width: 768px){
        margin: 75px 0 50px 0;
    }

    

    &:before{
        content: '';
        position: absolute;
        top: -86%;
        left: -135px;
        background: url('../src/images/sectiontitle-icon.png')no-repeat;
        width: 190px;
        height: 195px;

        @media(max-width: 400px){
            top: -50%;
        }
    }
}

.pl-6{
    padding-left: 6rem;
}

.mt-6{
    margin-top: 6rem;
}

.ml-6{
    margin-left: 4rem;

    @media(min-width: 1400px){
        margin-left: 6rem;
    }
}

.flex-row-center{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex-column-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

a{
    text-decoration: none;
    color: inherit;
    
    &:hover{
        text-decoration: none;
        color: inherit;
    }
}