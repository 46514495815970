
.cookies{
    position: fixed;
    background: #f8f8f8;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 100px;
    z-index: 99999;
    text-align: justify;
    color: #696969;
    transform: translateY(500px);
    transition: 1s transform;

    @media(max-width: 768px){
        padding: 20px;
        font-size: 13px;
    }
}

.goIn{
    transform: translateY(0px);
    transition: 1s transform;
}
