@import 'variables';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css?family=Titillium+Web:200,300,400,600,700&display=swap&subset=latin-ext');

// components
@import 'buttons';
@import 'header';

@import 'root';

// pages
@import 'page-home';

